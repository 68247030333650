import api from '@/api'

import useRouteStore from './route'
import useMenuStore from './menu'
import {
    checkLogin,
    loginAuth,
    logoutApi
} from '@/api/login/loginRequest'



import {
    encryption
} from '@/util/common'

// import {
//     getUserInfo,
//     login,
//     loginByMobile,
//     refreshTokenApi
// } from '/@/api/login/loginRequest'

const useUserStore = defineStore(
    // 唯一ID
    'user', {
        state: () => ({
            account: localStorage.account || '',
            nickname: localStorage.nickname || '',
            userId: localStorage.userId || '',
            token: localStorage.token || '',
            avatar: localStorage.avatar || '',
            failure_time: localStorage.failure_time || '',
            refresh_token: localStorage.refresh_token || '',
            tenantId: localStorage.tenantId || '',
            permissions: []
        }),
        getters: {
            isLogin: state => {
                let retn = false
                if (state.token) {
                    const time1 = new Date().getTime()
                    const time2 = state.failure_time * 1000
                    console.log('isLogin==', time1)
                    console.log('isLogin==', time2)
                    if (time1 < time2) {
                        retn = true
                    }
                }
                console.log('isLogin==isLogin=', retn)
                return retn
            }
        },
        actions: {
            login(data) {
                const endEnc =
                    import.meta.env.VITE_PWD_ENC_KEY
                console.log('login method data====', data)
                // console.log('login method endEnc====', endEnc)
                return new Promise((resolve, reject) => {
                    data.grant_type = 'password'
                    data.scope = 'server'
                    // 密码加密
                    const user = encryption({
                        data: data,
                        key: endEnc,
                        param: ['password']
                    })
                    // console.log('data promise ==user==', user)
                    loginAuth(user)
                        .then(res => {
                            console.log('login method res', res.user_info)
                            // 存储token 信息
                            localStorage.setItem('token', res.access_token)
                            localStorage.setItem('refresh_token', res.refresh_token)
                            localStorage.setItem('userInfo', res.user_info)
                            localStorage.setItem('failure_time', res.exp)

                            var tenantId = res.user_info.tenantId
                            // 设置租户信息到缓存
                            localStorage.setItem('tenantId', tenantId) // 写入到 session 存储
                            localStorage.setItem('nickname', res.user_info.nickname)
                            localStorage.setItem('account', res.user_info.username)
                            localStorage.setItem('userId', res.user_info.id)
                            localStorage.setItem('avatar', res.user_info.avatar)
                            this.account = res.user_info.username
                            this.nickname = res.user_info.nickname
                            this.userId = res.user_info.id
                            this.avatar = res.user_info.avatar
                            this.token = res.access_token
                            this.refresh_token = res.refresh_token
                            this.failure_time = res.exp
                            this.permissions = res.user_info.authorities
                            resolve()
                            // alertMsg().error(res.msg || '系统异常请联系管理员res');
                            // reject(res.msg);
                        })
                        .catch(err => {
                            // alertMsg().error(err?.msg || '系统异常请联系管理员err');
                            reject(err)
                            console.log(err)
                        })
                })
            },
            logout() {
                return new Promise((resolve, reject) => {
                    const routeStore = useRouteStore()
                    const menuStore = useMenuStore()
                    localStorage.removeItem('account')
                    localStorage.removeItem('nickName')
                    localStorage.removeItem('userId')
                    localStorage.removeItem('token')
                    localStorage.removeItem('failure_time')
                    localStorage.removeItem('avatar')
                    this.account = ''
                    this.nickName = ''
                    this.userId = ''
                    this.token = ''
                    this.failure_time = ''
                    this.avatar = ''
                    this.refresh_token = ''
                    this.permissions = ''
                    routeStore.removeRoutes()
                    menuStore.setActived(0)
                    var param = {
                        'platform': 'pc',
                        'ts': new Date().getTime()
                    }
                    logoutApi(param).then(async res => {
                        if (res && res.code === 200) {
                            // 退出成功
                            resolve()
                        }
                    }).catch(error => {
                        reject(error)
                    })

                })
            },
            // 获取我的权限
            getPermissions() {
                return new Promise((resolve, reject) => {
                    var param = {
                        'token': localStorage.getItem('token') || 'onNoToken',
                        'platform': 'pc',
                        'ts': new Date().getTime()
                    }
                    checkLogin(param).then(async res => {
                        if (res && res.hasOwnProperty('access_token') !== -1 || res.hasOwnProperty('refresh_token') !== -1) {
                            console.log('checkLogin is exec ........=', res.user_info)
                            localStorage.setItem('token', res.access_token)
                            localStorage.setItem('refresh_token', res.refresh_token)
                            localStorage.setItem('userInfo', res.user_info)
                            localStorage.setItem('failure_time', res.exp)
                            var tenantId = res.user_info.tenantId
                            // 设置租户信息到缓存
                            localStorage.setItem('tenantId', tenantId) // 写入到 session 存储
                            localStorage.setItem('nickname', res.user_info.nickname)
                            localStorage.setItem('account', res.user_info.username)
                            localStorage.setItem('userId', res.user_info.id)
                            localStorage.setItem('avatar', res.user_info.avatar)
                            this.account = res.user_info.username
                            this.nickname = res.user_info.nickname
                            this.userId = res.user_info.id
                            this.avatar = res.user_info.avatar
                            this.token = res.access_token
                            this.refresh_token = res.refresh_token
                            this.failure_time = res.exp
                            this.permissions = res.user_info.authorities
                            resolve(res)
                        } else {
                            // 同注销拷贝代码
                            const routeStore = useRouteStore()
                            const menuStore = useMenuStore()
                            localStorage.removeItem('token')
                            localStorage.removeItem('refresh_token')
                            localStorage.removeItem('userInfo')
                            localStorage.removeItem('userId')
                            localStorage.removeItem('tenantId')
                            localStorage.removeItem('nickname')
                            localStorage.removeItem('userId')
                            localStorage.removeItem('avatar')
                            this.userId = ''
                            this.nickname = ''
                            this.account = ''
                            this.token = ''
                            this.failure_time = ''
                            this.avatar = ''
                            routeStore.removeRoutes()
                            menuStore.setActived(0)
                            resolve()
                        }
                    }).catch(error => {
                        reject(error)
                    })
                })
            },
            editPassword(data) {
                return new Promise(resolve => {
                    api.post('member/edit/password', {
                        account: this.account,
                        password: data.password,
                        newpassword: data.newpassword
                    }, {
                        baseURL: '/mock/'
                    }).then(() => {
                        resolve()
                    })
                })
            }
        }
    }
)

export default useUserStore

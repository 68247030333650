import api from '@/api'
import url from '@/util/requestUrl'

import {
    validateIsNull
} from '@/util/toolsValidate'
const FORM_CONTENT_TYPE = 'application/x-www-form-urlencoded'

const appPxApi =
    import.meta.env.VITE_APP_PX_URL

/**
 * sendMobileCode 发送验证码
 * @param {query} query  参数
 */
export function sendMobileCode(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.sendMobileCode,
        method: 'get',
        params: query
    })
}
export function sendMobileCodeNoLogin(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.sendMobileCodeNo,
        method: 'get',
        headers: {
            skipToken: true,
            'Content-Type': FORM_CONTENT_TYPE
        },
        params: query
    })
}


/**
 * loginByBase 登录
 * @param {query} query  参数
 */
export function loginByBase(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.loginByBase,
        method: 'get',
        params: query
    })
}
/**
 * regBase注册
 * @param {query} query  参数
 */
export function regBase(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.regBase,
        method: 'get',
        headers: {
            skipToken: true,
            'Content-Type': FORM_CONTENT_TYPE
        },
        params: query
    })
}

/**
 * resetPassMethod找回密码功能
 * @param {query} query  参数
 */
export function resetPassApi(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.resetPass,
        method: 'post',
        params: query
    })
}



/**
 * logoutApi
 * @param {query} query  参数
 */
export function logoutApi(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.logout,
        method: 'delete',
        params: query
    })
}

/**
 * getUserDetail
 * @param {query} query  参数
 */
export function getUserDetail(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.getUserDetail,
        method: 'get',
        params: query
    })
}
/**
 * sendEmailCode
 * @param {query} query  参数
 */
export function sendEmailCode(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.sendEmailCode,
        method: 'get',
        params: query
    })
}

/**
 * updateUserInfo
 * @param {query} query  参数
 */
export function updateUserInfo(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.updateUserInfo,
        method: 'get',
        params: query
    })
}

/**
 * updatePasswd
 * @param {query} query  参数
 */
export function updatePasswd(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.updatePasswd,
        method: 'post',
        params: query
    })
}
/**
 * bindNewMobile
 * @param {query} query  参数
 */
export function bindNewMobile(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.bindNewMobile,
        method: 'get',
        params: query
    })
}

/**
 * bindNewEmail
 * @param {query} query  参数
 */
export function bindNewEmail(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.bindNewEmail,
        method: 'get',
        params: query
    })
}

/**
 * sendSmsCode
 * @param {query} query  参数
 */
export function sendSmsCode(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.sendMobileCode,
        method: 'get',
        params: query
    })
}

/**
 * uploadAvatar
 * @param {query} data  参数
 */
export function uploadAvatar(data) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.uploadAvatar,
        method: 'post',
        data:data
    })
}
/**
 * getAllTags
 * @param {query} data  参数
 */
export function getAllTags(data) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.getAllTags,
        method: 'get',
        params: data
    })
}
/**
 * 以下是新的 方法
 * -------------------------------------------------------------------------------
 * -------------------------------------------------------------------------------
 */

/**
 * checkLogin检查登录
 * @param {query} query  参数
 */
export function checkLogin(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.checkLogin,
        method: 'get',
        params: query
    })
}

/**
 * 登录
 * @param data
 */
export const loginAuth = data => {
    // console.log('data: ' + data)
    const client =
        import.meta.env.VITE_OAUTH2_PASSWORD_CLIENT
    const basicAuth = 'Basic ' + window.btoa(client)
    localStorage.setItem('basicAuth', basicAuth)
    // console.log('basicAuth: ' + basicAuth)
    const {
        username,
        password,
        randomStr,
        code,
        grant_type,
        scope
    } = data
    return api.request({
        url: appPxApi + '/auth/oauth2/token',
        method: 'post',
        params: {
            username,
            randomStr,
            code,
            grant_type,
            scope
        },
        data: {
            password: password
        },
        headers: {
            skipToken: true,
            Authorization: basicAuth,
            'Content-Type': FORM_CONTENT_TYPE
        }
    })
}

/**
 * 登录 手机号
 * @param data
 */

export const loginByMobile = (mobile, code) => {
    const grant_type = 'mobile'
    const scope = 'server'
    const client =
        import.meta.env.VITE_OAUTH2_PASSWORD_CLIENT
    const basicAuth = 'Basic ' + window.btoa(client)
    localStorage.setItem('basicAuth', basicAuth)
    // console.log('basicAuth: ' + basicAuth)

    return api.request({
        url: appPxApi + '/auth/oauth2/token',
        headers: {
            skipToken: true,
            Authorization: basicAuth,
            'Content-Type': FORM_CONTENT_TYPE
        },
        method: 'post',
        params: {
            mobile: 'SMS@' + mobile,
            code: code,
            grant_type,
            scope
        }
    })
}

export const refreshTokenApi = refresh_token => {
    const grant_type = 'refresh_token'
    const scope = 'server'
    // 获取当前选中的 basic 认证信息
    const basicAuth = localStorage.getItem('basicAuth')
    return api.request({
        url: '/auth/oauth2/token',
        headers: {
            skipToken: true,
            Authorization: basicAuth,
            'Content-Type': FORM_CONTENT_TYPE
        },
        method: 'post',
        params: {
            refresh_token,
            grant_type,
            scope
        }
    })
}

/**
 * 校验令牌，若有效期小于半小时自动续期
 * @param refreshLock
 */
// export const checkToken = (refreshTime, refreshLock) => {
//     const basicAuth = Session.get('basicAuth')
//     api.request({
//             url: appPxApi + '/auth/token/check_token',
//             headers: {
//                 skipToken: true,
//                 Authorization: basicAuth,
//                 'Content-Type': FORM_CONTENT_TYPE
//             },
//             method: 'get',
//             params: {
//                 token: Session.getToken()
//             }
//         })
//         .then(response => {
//             if (validateIsNull(response) || response.code === 1) {
//                 clearInterval(refreshTime)
//                 return
//             }
//             const expire = Date.parse(response.data.expiresAt)
//             if (expire) {
//                 const expiredPeriod = expire - new Date().getTime()
//                 // 小于半小时自动续约
//                 if (expiredPeriod <= 30 * 60 * 1000) {
//                     if (!refreshLock) {
//                         refreshLock = true
//                         // useUserInfo()
//                         //     .refreshToken()
//                         //     .catch(() => {
//                         //         clearInterval(refreshTime)
//                         //     })
//                         refreshLock = false
//                     }
//                 }
//             }
//         })
//         .catch(() => {
//             // 发生异常关闭定时器
//             clearInterval(refreshTime)
//         })
// }

/**
 * 获取用户信息
 */
export const getUserInfo = () => {
    return api.request({
        url: appPxApi + '/admin/user/info',
        method: 'get'
    })
}

export const logout = () => {
    return api.request({
        url: appPxApi + '/auth/token/logout',
        method: 'delete'
    })
}

const API_PREFIX = '/upsapi';

export const url = {
    getIndexTopMeu: API_PREFIX + '/ext/api/v1/index/getIndexTopMenu',
    // 成语
    getIdiom: API_PREFIX + '/ext/api/v1/idiom/getIdiom',
    getIdiomMp3List: API_PREFIX + '/ext/api/v1/idiom/getIdiomMp3List',
    getIdiomTodayList: API_PREFIX + '/ext/api/v1/idiom/getIdiomTodayList',
    getIdiomTopsList: API_PREFIX + '/ext/api/v1/idiom/getIdiomTopsList',
    // 提醒
    getTips: API_PREFIX + '/ext/api/v1/tips/getTips',
    // 俗语
    getAncientSayingTop: API_PREFIX + '/ext/api/v1/saying/getAncientSayingTop',
    getAncientSayingMp3List: API_PREFIX + '/ext/api/v1/saying/getAncientSayingMp3List',
    getAncientSayingList: API_PREFIX + '/ext/api/v1/saying/getAncientSayingList',
    getAncientSayingDetail: API_PREFIX + '/ext/api/v1/saying/getAncientSayingDetail',
    // 古字
    getAncientWywList: API_PREFIX + '/ext/api/v1/wyw/getAncientWywList',
    getAncientWywDetail: API_PREFIX + '/ext/api/v1/wyw/getAncientWywDetail',
    getAncientWywMp3List: API_PREFIX + '/ext/api/v1/wyw/getAncientWywMp3List',
    getWywTodayList: API_PREFIX + '/ext/api/v1/wyw/getWywTodayList',
    getWywTopsList: API_PREFIX + '/ext/api/v1/wyw/getWywTopsList',
    // 古文
    getAncientPoetryList: API_PREFIX + '/ext/api/v1/poetry/getAncientPoetryList',
    getIndexAncientPoetryListUrl: API_PREFIX + '/ext/api/v1/poetry/getIndexAncientPoetryList',
    getAncientPoetryDetail: API_PREFIX + '/ext/api/v1/poetry/getAncientPoetryDetail',
    getAncientGushiMp3List: API_PREFIX + '/ext/api/v1/poetry/getAncientGushiMp3List',
    getPoetryTodayList: API_PREFIX + '/ext/api/v1/poetry/getPoetryTodayList',
    getPoetryTopsList: API_PREFIX + '/ext/api/v1/poetry/getPoetryTopsList',
    // 图书
    getAncientBookList: API_PREFIX + '/ext/api/v1/book/getAncientBookList',
    getAncientBookContentDetail: API_PREFIX + '/ext/api/v1/book/getAncientBookContentDetail',
    getAncientBookDetail: API_PREFIX + '/ext/api/v1/book/getAncientBookDetail',
    getBooksContentTopsList: API_PREFIX + '/ext/api/v1/book/getBooksContentTopsList',
    getAncientBookMp3List: API_PREFIX + '/ext/api/v1/book/getAncientBookMp3List',
    getBookTodayList: API_PREFIX + '/ext/api/v1/book/getBookTodayList',
    getBooksTopsList: API_PREFIX + '/ext/api/v1/book/getBooksTopsList',

    // 基础登录
    loginByBase: API_PREFIX + '/ext/api/v1/u/loginByBase',


    //单独配置
    checkLogin: '/auth/token/check_token',
    logout: '/auth/token/logout',

    // 登录相关
    resetPass: API_PREFIX + '/api/inner/user/updatePasswd',
    getUserDetail: API_PREFIX + '/api/v1/user/info',
    updateUserInfo: API_PREFIX + '/api/v1/user/changeNameAndNickname',
    sendEmailCode: API_PREFIX + '/api/v1/user/sendEmailCode',
    bindNewEmail: API_PREFIX + '/api/v1/user/bindNewEmail',
    updatePasswd: API_PREFIX + '/api/v1/user/updatePasswd',
    bindNewMobile: API_PREFIX + '/api/v1/user/bindNewMobile',
    sendMobileCode: API_PREFIX + '/api/v1/user/sendSmsCode',
    sendMobileCodeNo: API_PREFIX + '/api/inner/user/sendSmsCodeNoLogin',
    regBase: API_PREFIX + '/api/inner/user/regBase',

    // 上传头像
    uploadAvatar: API_PREFIX + '/api/v1/upload/uploadAvatar',

    getDisplayContent: API_PREFIX + '/ext/api/v2/index/getDisplayContent',
    getIndexDisplayContentList: API_PREFIX + '/ext/api/v2/index/getIndexDisplayContentList',
    getAllTags: API_PREFIX + '/ext/api/v2/index/getAllTags',




    // 获取字帖内容
    getFontContentListUrl: API_PREFIX + '/pub/api/v2/fontContent/getFontContentList',
    getFontContentListUrl2: API_PREFIX + '/pub/api/v2/fontContent/getFontContentList2',
    // 获取英语字帖
    getEnglishContentListUrl: API_PREFIX + '/pub/api/v2/fontContent/getEnglishContentList',
    // 无需登录直接实现文件下载
    getSignUrlByKeysUrl: API_PREFIX + '/pub/api/v2/fontContent/getSignUrlByKeys',
    previewPdfUrl: API_PREFIX + '/pub/api/v2/fontContent/previewPdfUrl',

    // 登陆以后实施的功能 -打印以及预览
    generatePdfUrl: API_PREFIX + '/ext/api/v2/fontContent/generatePdfUrl',
    getSignUrl: API_PREFIX + '/ext/api/v2/fontContent/getSignUrl',


    // 权限
    getPermissions: API_PREFIX + '/ext/api/v1/index/getPermissions'
};
// 输出默认菜单
export const routeData = {
    data: [
        // 推荐
        {
            meta: {
                title: '推荐',
                icon: 'sidebar-default'
            },
            path: '/',
            children: [{
                path: '/',
                component: 'Layout',
                redirect: '/view/index',
                name: '推荐',
                meta: {
                    title: '多级导航',
                    icon: 'sidebar-menu'
                }
            }]
        },
        // 古诗
        {
            meta: {
                title: '古诗',
                icon: 'sidebar-default'
            },
            path: '/gushi',
            children: [{
                path: '/gushi',
                component: 'Layout',
                redirect: '/view/gushi',
                name: '古诗',
                meta: {
                    title: '古诗',
                    icon: 'sidebar-menu'
                }
            }]
        },
        // 古书
        // {
        //     meta: {
        //         title: '古书',
        //         icon: 'sidebar-default'
        //     },
        //     path: '/gushu',
        //     children: [{
        //         path: '/gushu',
        //         component: 'Layout',
        //         redirect: '/view/gushu',
        //         name: '古书',
        //         meta: {
        //             title: '古书',
        //             icon: 'sidebar-menu'
        //         }
        //     }]
        // },
        // 名句
        // {
        //     meta: {
        //         title: '名句',
        //         icon: 'sidebar-default'
        //     },
        //     path: '/mingju',
        //     children: [{
        //         path: '/mingju',
        //         component: 'Layout',
        //         redirect: '/view/mingju',
        //         name: '名句',
        //         meta: {
        //             title: '名句',
        //             icon: 'sidebar-menu'
        //         }
        //     }]
        // },
        // 古词
        {
            meta: {
                title: '古字',
                icon: 'sidebar-default'
            },
            path: '/wenyanwen',
            children: [{
                path: '/wenyanwen',
                component: 'Layout',
                redirect: '/view/wenyanwen',
                name: '古字',
                meta: {
                    title: '古字',
                    icon: 'sidebar-menu'
                }
            }]
        },
        // 成语
        {
            meta: {
                title: '成语',
                icon: 'sidebar-default'
            },
            path: '/chengyu',
            children: [{
                path: '/chengyu',
                component: 'Layout',
                redirect: '/view/chengyu',
                name: '成语',
                meta: {
                    title: '成语',
                    icon: 'sidebar-menu'
                }
            }]
        },
        // 字帖
        {
            meta: {
                title: '字帖',
                icon: 'sidebar-default'
            },
            path: '/zitie',
            children: [{
                path: '/zitie',
                component: 'Layout',
                redirect: '/view/zitie',
                name: '字帖',
                meta: {
                    title: '字帖',
                    icon: 'sidebar-menu'
                }
            }]
        }
    ]
}
export default url

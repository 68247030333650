<route>
    {
        name: 'top',
        meta: {
            title: ""
        }
    }
    </route>

<script setup>
import { reactive, onMounted, ref } from 'vue'
import { getAncientSayingTop } from '@/api/saying/apiSayingRequest'
import { getDisplayContent } from '@/api/index/apiIndexRequest'

const router = useRouter()
// 底部数据
let botomData = reactive({
    data: [],
    noData: false
})
// 顶部数据
let tops1Data = reactive({
    data: [],
    noData: false
})
// 搜索参数
let searchParam = reactive({
    key: '',
    source: ''
})
onMounted(() => {
    var keyMap = router.currentRoute.value.query
    let paramKey = keyMap.source
    if (paramKey) {
        searchParam.source = paramKey
    }
    getDisplayContentGushi()
    getBottomList()
})

// 请求关键词
function getBottomList() {
    var param = {
        type: 'index'
    }
    getAncientSayingTop(param).then(async res => {
        botomData.noData = true
        if (res.data && res.data.results) {
            botomData.data = res.data.results
            if (res.data.results && res.data.results.length > 0) {
                botomData.noData = false
            }
        }
    }).catch(res => {
        console.log(res)
    })
}
// 获取顶部数据
function getDisplayContentGushi() {
    var param = {
        categoryId: 34
    }
    getDisplayContent(param).then(async res => {
        tops1Data.noData = true
        if (res.data) {
            tops1Data.data = res.data
            if (res.data && res.data.length > 0) {
                tops1Data.noData = false
            }
        }
    }).catch(res => {
        console.log(res)
    })
}

</script>

<template>
    <div>
        <!--
        <page-header title="">
          <template #content>
                    <div>
                        <div style="margin-bottom: 5px;">这是一款<b class="text-emphasis">开箱即用</b>的中后台框架，同时它也经历过数十个真实项目的技术沉淀，确保框架在开发中可落地、可使用、可维护</div>
                        <div>注：在作者就职的公司，本框架已在电商、直播、OA、ERP等多个不同领域的中后台系统中应用并稳定运行</div>
                    </div>
                </template>
                <el-button-group style="margin-right: 10px;">
                    <el-button type="success" size="large" plain @click="open('https://www.lehaoyuan.com')">开发文档</el-button>
                    <el-dropdown>
                        <el-button type="danger" size="large">
                            代码仓库
                            <el-icon class="el-icon--right">
                                <svg-icon name="ep:arrow-down" />
                            </el-icon>
                        </el-button>
                        <template #dropdown>
                            <el-dropdown-menu>
                                <el-dropdown-item @click="open('https://www.lehaoyuan.com')">Gitee</el-dropdown-item>
                                <el-dropdown-item @click="open('https://www.lehaoyuan.com')">Github</el-dropdown-item>
                            </el-dropdown-menu>
                        </template>
                    </el-dropdown>
                </el-button-group>
        </page-header>  -->

        <el-carousel
            :interval="4000"
            type="card"
            style="padding-top: 5px;"
        >
            <el-carousel-item
                v-for="item in 6"
                :key="item"
            >
                <h3
                    text="2xl"
                    justify="center"
                >
                    {{ item }}
                </h3>
            </el-carousel-item>
        </el-carousel>
        <div>
            <el-row
                :gutter="24"
                style="margin: 2px;"
            >
                <el-col
                    :xs="24"
                    :sm="24"
                    :md="24"
                    :lg="6"
                    :xl="6"
                >
                    <div class="grid-content ep-bg-purple">
                        <el-card
                            :body-style="{ padding: '0px' }"
                            shadow="hover"
                        >
                            <img
                                fit="fit"
                                width="320"
                                height="224"
                                src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"
                                class="image"
                            >
                            <div style="padding: 14px;">
                                <span>标题标题标题标题</span>
                                <div class="bottom2">
                                    <time class="time2">副标题</time>
                                    <el-button
                                        plain
                                        size="small"
                                        type="danger"
                                        @click="lickVoice()"
                                    >
                                        <template #icon>
                                            <el-icon>
                                                <svg-icon name="ep:video-play" />
                                            </el-icon>
                                        </template>
                                        朗读
                                    </el-button>
                                </div>
                            </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col
                    :xs="24"
                    :sm="24"
                    :md="24"
                    :lg="6"
                    :xl="6"
                >
                    <div class="grid-content ep-bg-purple">
                        <el-card
                            :body-style="{ padding: '0px' }"
                            shadow="hover"
                        >
                            <img
                                width="320"
                                height="224"
                                src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"
                                class="image"
                            >
                            <div style="padding: 14px;">
                                <span>标题标题标题标题</span>
                                <div class="bottom2">
                                    <time class="time2">副标题</time>
                                    <el-button
                                        plain
                                        size="small"
                                        type="danger"
                                        @click="lickVoice()"
                                    >
                                        <template #icon>
                                            <el-icon>
                                                <svg-icon name="ep:video-play" />
                                            </el-icon>
                                        </template>
                                        朗读
                                    </el-button>
                                </div>
                            </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col
                    :xs="24"
                    :sm="24"
                    :md="24"
                    :lg="6"
                    :xl="6"
                >
                    <div class="grid-content ep-bg-purple">
                        <el-card
                            :body-style="{ padding: '0px' }"
                            shadow="hover"
                        >
                            <img
                                width="320"
                                height="224"
                                src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"
                                class="image"
                            >
                            <div style="padding: 14px;">
                                <span>标题标题标题标题</span>
                                <div class="bottom2">
                                    <time class="time2">副标题</time>
                                    <el-button
                                        plain
                                        size="small"
                                        type="danger"
                                        @click="lickVoice()"
                                    >
                                        <template #icon>
                                            <el-icon>
                                                <svg-icon name="ep:video-play" />
                                            </el-icon>
                                        </template>
                                        朗读
                                    </el-button>
                                </div>
                            </div>
                        </el-card>
                    </div>
                </el-col>
                <el-col
                    :xs="24"
                    :sm="24"
                    :md="24"
                    :lg="6"
                    :xl="6"
                >
                    <div class="grid-content ep-bg-purple">
                        <el-card
                            :body-style="{ padding: '0px' }"
                            shadow="hover"
                        >
                            <img
                                width="320"
                                height="224"
                                src="https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg"
                                class="image"
                            >
                            <div style="padding: 14px;">
                                <span>标题标题标题标题</span>
                                <div class="bottom2">
                                    <time class="time2">副标题</time>
                                    <el-button
                                        plain
                                        size="small"
                                        type="danger"
                                        @click="lickVoice()"
                                    >
                                        <template #icon>
                                            <el-icon>
                                                <svg-icon name="ep:video-play" />
                                            </el-icon>
                                        </template>
                                        朗读
                                    </el-button>
                                </div>
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-divider content-position="left" />
        <div>
            <el-row
                :gutter="24"
                style="margin: 2px;"
            >
                <el-col
                    v-for="item in tops1Data.data"
                    :key="item.id"
                    :xs="24"
                    :sm="24"
                    :md="24"
                    :lg="6"
                    :xl="6"
                >
                    <div class="grid-content ep-bg-purple">
                        <el-card
                            class="box-card"
                            shadow="hover"
                        >
                            <template #header>
                                <div class="card-header">
                                    <span>
                                        <el-link
                                            type="primary"
                                            class="card-title"
                                        >{{ item.displayName }}</el-link>
                                    </span>
                                    <span>
                                        <el-button
                                            plain
                                            size="small"
                                            type="danger"
                                            @click="playVoice()"
                                        >
                                            <template #icon>
                                                <el-icon>
                                                    <svg-icon name="ep:video-play" />
                                                </el-icon>
                                            </template>
                                            朗读
                                        </el-button>
                                    </span>
                                </div>
                            </template>
                            <div
                                v-for="subItem in item.searchData.poetryContentList" :key="subItem.id"
                                class="text item"
                            >
                                <div style="color: #2c2c2c;font-size: 16px;line-height: 1.8;">{{ subItem.content }} </div>
                            </div>

                            <div>
                                <span class="card-author">{{ item.searchData.author }}〔{{ item.searchData.remark }}〕</span>

                                <!-- <time class="time">参考资料</time> -->
                                <div class="bottom">
                                    <!-- <el-button
                                        plain
                                        size="small"
                                        type="danger"
                                        @click="playVoice()"
                                    >
                                        <template #icon>
                                            <el-icon>
                                                <svg-icon name="ep:video-play" />
                                            </el-icon>
                                        </template>
                                        朗读
                                    </el-button>
                                    <el-button
                                        plain
                                        size="small"
                                        type="danger"
                                        @click="playVoice()"
                                    >
                                        <template #icon>
                                            <el-icon>
                                                <svg-icon name="ep:printer" />
                                            </el-icon>
                                        </template>
                                        打印
                                    </el-button> -->
                                </div>
                            </div>
                        </el-card>
                    </div>
                </el-col>
            </el-row>
        </div>
        <el-divider content-position="left" />

        <page-main title="经典歇后语">
            <div class="question">
                <ol class="answer">
                    <li
                        v-for="boottomItem in botomData.data"
                        :key="boottomItem.id"
                    >
                        <span>{{ boottomItem.riddle }}</span>
                        {{ boottomItem.answer }}
                    </li>
                </ol>
            </div>
        </page-main>
    </div>
</template>

    <style lang="scss" scoped>
    .el-carousel__item h3 {
        color: #475669;
        opacity: 0.75;
        line-height: 480px;
        margin: 0;
        text-align: center;
    }
    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }
    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .card-title {
        color: #333;
        font-size: 22px;
        font-weight: bold;
    }
    .card-author {
        font-weight: bold;
        font-size: 15px;
    }
    .text {
        font-size: 15px;
    }
    .item {
        margin-bottom: 18px;
    }
    .box-card {
        width: auto;
        height: 350px;
    }
    .time {
        font-size: 12px;
        color: #999;
        margin-top: 13px;
        line-height: 1.8;
        display: flex;
        justify-content: space-between;
    }
    .bottom {
        margin-top: 13px;
        line-height: 12px;
        display: flex;
        justify-content: end;
        align-items: center;
    }
    .time2 {
        font-size: 12px;
        color: #999;
    }
    .bottom2 {
        margin-top: 13px;
        line-height: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .button {
        padding: 0;
        min-height: auto;
    }
    .image {
        width: 100%;
        display: block;
    }
    .el-row {
        margin-bottom: 5px;
    }
    .el-row:last-child {
        margin-bottom: 0;
    }
    .el-col {
        border-radius: 4px;
    }
    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }
    .text-emphasis {
        text-emphasis-style: "❤";
    }
    .ecology {
        padding: 10px 0 0;
        &.vue {
            h1 {
                color: #41b883;
            }
        }
        &.fa {
            h1 {
                color: #e60000;
            }
        }
        &.osa {
            h1 {
                color: #67c23a;
            }
        }
        .main {
            text-align: center;
            img {
                display: block;
                margin: 0 auto;
            }
            h1 {
                margin: 10px auto 0;
                text-align: center;
            }
            h2 {
                font-size: 16px;
                font-weight: normal;
                color: var(--el-text-color-secondary);
                text-align: center;
            }
        }
        .el-carousel {
            box-shadow: var(--el-box-shadow-light);
            transition: var(--el-transition-box-shadow);
        }
        ul li {
            line-height: 28px;
        }
    }
    .question {
        .answer {
            margin: 20px 0 0;
            padding-left: 20px;
            font-size: 16px;
            color: var(--el-text-color-secondary);
            li {
                margin-bottom: 10px;
                line-height: 1.5;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            span {
                color: var(--el-text-color-primary);
                font-weight: bold;
            }
        }
    }
    </style>

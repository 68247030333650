import api from '@/api'
import url from '@/util/requestUrl'
/**
 * 获取成语库
 * @param {query} query  参数
 */
export function getChengyuList(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.getIdiom,
        method: 'get',
        params: query
    })
}

/**
 * 获取阅读
 * @param {query} query  参数
 */
export function getIdiomMp3List(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.getIdiomMp3List,
        method: 'get',
        params: query
    })
}
/**
 * 获取阅读
 * @param {query} query  参数
 */
export function getIdiomTodayList(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.getIdiomTodayList,
        method: 'get',
        params: query
    })
}
/**
 * getIdiomTopsList
 * @param {query} query  参数
 */
export function getIdiomTopsList(query) {
    // 通过 mock 进行数据处理
    return api.request({
        url: url.getIdiomTopsList,
        method: 'get',
        params: query
    })
}

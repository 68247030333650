<route>
    {
        name: 'index',
        meta: {
            title: ""
        }
    }
    </route>

<script setup>
import { reactive, onMounted, ref } from 'vue'
const router = useRouter()
import { getAncientGushiMp3List, getPoetryTodayList, getPoetryTopsList } from '@/api/gushi/apiGushiRequest'
import { getIdiomMp3List } from '@/api/chengyu/apiChengyuRequest'
import { getAncientSayingTop } from '@/api/saying/apiSayingRequest'
import { getIndexDisplayContentList } from '@/api/index/apiIndexRequest'
import { Audio, props, commonText, setGlobalColor, setPcAndH5PaddingCssStyle, cssData, getCurrentMode, pushUrl } from '@/util/common'
import useSettingsStore from '@/store/modules/settings'
import 'APlayer/dist/APlayer.min.css'
import APlayer from 'APlayer'
import { getAllTags } from '@/api/login/loginRequest'
const playerRef = ref()
const globalPlayId = reactive({
  playId: null
})
// const { proxy } = getCurrentInstance()
const state = reactive({
  instance: null
})

let inputSearchTips = reactive({
  searchWord: '请输入搜索关键词',
  indexId: 0
})

let items = ref({
  allTags: [],
  tagTypes: [],
  pinyinTypes: commonText.pinyinText,
  // 策、诗、词、曲、八股、骈文
  wordLengthTypes: commonText.wordLengthText
})

// 搜索参数
let searchParam = ref({
  key: '',
  formatText: '',
  startPinyin: '',
  wordLength: '',
  tags: ''
}
)
let contentList = reactive({
  data: [],
  noData: false,
  pageNo: 1,
  pageSize: 10,
  totalPage: 0,
  totalRecord: 0
})
let tipsData = reactive({
  data: [],
  noData: false
})
let botomData = reactive({
  data: [],
  noData: false
})
let todayData = reactive({
  data: [],
  noData: false
})
let topsData = reactive({
  data: [],
  noData: false
})
const backTopFlag = ref(false)
const backTop = () => {
  let top = document.documentElement.scrollTop// 获取点击时页面的滚动条纵坐标位置
  const timeTop = setInterval(() => {
    document.documentElement.scrollTop = top -= 50// 一次减50往上滑动
    if (top <= 0) {
      clearInterval(timeTop)
    }
  }, 5)// 定时调用函数使其更顺滑
}
const handleScroll = () => {
  if (document.documentElement.scrollTop > 20) {
    backTopFlag.value = true
  } else {
    backTopFlag.value = false
  }
  // 往下滑超过20则显示 否则则不显示按钮
}
//  播放
function clickVoice(itemSub, allType) {
  console.log('globalPlayId=', globalPlayId)
  let str = {
    otherId: itemSub.businessId
  }
  contentList.data.map(item => {
    if (itemSub.id === item.id) {
      item.plain = true
      item.voiceText = '播放中'
    } else {
      item.plain = false
      item.voiceText = '播放'
    }
    return item
  })
  // getIdiomMp3List
  // getAncientGushiMp3List
  if (allType == 'chengyu') {
    getIdiomMp3List(str).then(res => {
      let audioList = res.data.map(value => new Audio(value.author, value.title, value.url, value.pic, value.lrc))
      state.instance = new APlayer({
        container: playerRef.value,
        fixed: props.fixed,
        mini: props.mini,
        autoplay: props.autoplay,
        theme: props.theme,
        loop: props.loop,
        order: props.order,
        preload: props.preload,
        volume: props.volume,
        mutex: props.mutex,
        lrcType: props.lrcType,
        listFolded: props.listFolded,
        listMaxHeight: props.listMaxHeight,
        storageName: props.storageName,
        audio: audioList
      })

      // https://aplayer.js.org/#/zh-Hans/
      state.instance.on('ended', function () {
        contentList.data.map(item => {
          item.plain = false
          item.voiceText = '播放'
          return item
        })
      })
      state.instance.on('play', function () {
        console.log('player play')
      })
      state.instance.on('pause', function () {
        console.log('player pause')
        if (itemSub.id == globalPlayId.playId) {
          pauseMp3()
        }
      })
      state.instance.on('canplay', function () {
        console.log('player canplay')
      })
      state.instance.on('seeked', function () {
        console.log('player seeked')
      })
      state.instance.on('seeking', function () {
        console.log('player seeking')
      })
      state.instance.on('stalled', function () {
        console.log('player stalled')
      })
      globalPlayId.playId = itemSub.id
    })
  } else if (allType == 'idiom') {
    getAncientGushiMp3List(str).then(res => {
      let audioList = res.data.map(value => new Audio(value.author, value.title, value.url, value.pic, value.lrc))
      state.instance = new APlayer({
        container: playerRef.value,
        fixed: props.fixed,
        mini: props.mini,
        autoplay: props.autoplay,
        theme: props.theme,
        loop: props.loop,
        order: props.order,
        preload: props.preload,
        volume: props.volume,
        mutex: props.mutex,
        lrcType: props.lrcType,
        listFolded: props.listFolded,
        listMaxHeight: props.listMaxHeight,
        storageName: props.storageName,
        audio: audioList
      })

      // https://aplayer.js.org/#/zh-Hans/
      state.instance.on('ended', function () {
        contentList.data.map(item => {
          item.plain = false
          item.voiceText = '播放'
          return item
        })
      })
      state.instance.on('play', function () {
        console.log('player play')
      })
      state.instance.on('pause', function () {
        console.log('player pause')
        if (itemSub.id == globalPlayId.playId) {
          pauseMp3()
        }
      })
      state.instance.on('canplay', function () {
        console.log('player canplay')
      })
      state.instance.on('seeked', function () {
        console.log('player seeked')
      })
      state.instance.on('seeking', function () {
        console.log('player seeking')
      })
      state.instance.on('stalled', function () {
        console.log('player stalled')
      })
      globalPlayId.playId = itemSub.id
    })
  }

}

// 销毁
function destroyMp3() {
  if (state.instance) {
    state.instance.destroy()
  }
}
// 暂停播放
function pauseMp3() {
  if (state.instance) {
    state.instance.pause()
  }
}

// 刷新之前
onBeforeUpdate(() => {
  contentList.data = []
})

let timer = null
onMounted(() => {
  useSettingsStore().setTitle('字帖打印、古诗字帖打印、成语字帖、字帖打印、数学口算模版')
  setGlobalColor()
  setPcAndH5PaddingCssStyle()
  if (getCurrentMode() === 'mobile') {
    pushUrl('mobileindex', '')
  }
  window.addEventListener('scroll', handleScroll)

  window.addEventListener('keydown', keyDown)
  // 获取标签
  getAllTagsMethod('poetry')
  // 请求默认数据
  getChengyuListMethod(2)
  // 获取今日推荐
  getIdiomTodayListMethod()

  // 获取 最新排名视频
  getIdiomTopsListMethod()
  // 每5s刷新数据
  timer = setInterval(() => {
    // if (tipsData.data) {
    //     var tempData = tipsData.data
    //     var indexIdTotal = parseInt(tempData.chengyu.length)
    //     var indexId = parseInt(inputSearchTips.indexId)
    //     if (indexId < indexIdTotal) {
    //         var tt = indexId
    //         inputSearchTips.searchWord = tempData.chengyu[tt].tips
    //         inputSearchTips.indexId = tt + 1
    //     } else {
    //         inputSearchTips.indexId = 0
    //         inputSearchTips.searchWord = '请输入搜索关键词'
    //     }
    // }
  }, 10000)
  // 请求底部信息
  getChengyuBottomList()
})

// 现回车键搜索
const keyDown = e => {
  // 如果是回车则执行搜索方法
  if (e.keyCode == 13) {
    getChengyuListMethod()
  }
}

// 移除
onUnmounted(() => {
  window.removeEventListener('keydown', keyDown, false)
  window.removeEventListener('scroll', handleScroll)

})
onBeforeUnmount(() => {
  clearInterval(timer)
  timer = null
})
watchEffect(() => {
  // console.log('watchEffect', contentList.data)
})

// 获取排行榜的数据
function getIdiomTopsListMethod() {
  getPoetryTopsList().then(async res => {
    topsData.noData = true
    if (res.data && res.data.results) {
      topsData.data = res.data.results
      if (res.data && res.data.results.length > 0) {
        topsData.noData = false
      }
    }
  }).catch(res => {
    console.log(res)
  })
}
// 请求关键词
function getChengyuBottomList() {
  var param = {
    type: 'index'
  }
  getAncientSayingTop(param).then(async res => {
    botomData.noData = true
    if (res.data && res.data.results) {
      botomData.data = res.data.results
      if (res.data.results && res.data.results.length > 0) {
        botomData.noData = false
      }
    }
  }).catch(res => {
    console.log(res)
  })
}
// 请求今日推荐
function getIdiomTodayListMethod() {
  var param = {
    pageSize: 5
  }
  getPoetryTodayList(param).then(async res => {
    todayData.noData = true
    if (res.data && res.data.results) {
      todayData.data = res.data.results
      if (res.data.results && res.data.results.length > 0) {
        todayData.noData = false
      }
    }
  }).catch(res => {
    console.log(res)
  })
}

// 请求数据
function getChengyuListMethod(defaultMethod) {
  // 如果不是是直接点击搜索，则不使用提示词语
  // console.log(defaultMethod)
  var key = searchParam.value.key
  // console.log(key)
  key = key.replace(' ', '')
  if (key.indexOf('·') != -1) {
    key = key.replace('·', '')
  }
  if (key.indexOf('/') != -1) {
    key = key.replace('/', '')
  }
  var param = {
    'pageNo': contentList.pageNo,
    'pageSize': contentList.pageSize,
    'key': key,
    'formatText': searchParam.value.formatText,
    'defaultMethod': defaultMethod,
    'startPinyin': searchParam.value.startPinyin,
    'wordLength': searchParam.value.wordLength,
    'tags': searchParam.value.tags
  }
  getIndexDisplayContentList(param).then(async res => {
    contentList.noData = true
    if (res.data) {
      contentList.data = res.data.results
      contentList.pageNo = parseInt(res.data.currentPage)
      contentList.pageSize = parseInt(res.data.pageSize)
      contentList.totalPage = parseInt(res.data.totalPage)
      contentList.totalRecord = parseInt(res.data.totalRecord)
      if (res.data.results && res.data.results.length > 0) {
        contentList.noData = false
      }
    }
  }).catch(res => {
    console.log(res)
  })
}

// 点击右侧搜索
function clickSearchFull(word, type) {
  word = word.replace(' ', '')
  if (word.indexOf('·') != -1) {
    word = word.replace('·', '')
  }
  if (word.indexOf('/') != -1) {
    word = word.replace('/', '')
  }
  backTop()
  let route2 = null
  if (type === 'content') {
    route2 = router.resolve({
      path: '/gushi',
      query: {
        key: word
      }
    })
  } else {
    console.log('word==', word)
    route2 = router.resolve({
      path: '/gushi',
      query: {
        tags: word
      }
    })
  }
  window.open(route2.href, '_blank')
  // searchParam.value.key = word
  // searchParam.value.formatText = ''
  // searchParam.value.startPinyin = ''
  // searchParam.value.wordLength = ''
  // searchParam.value.tags = ''
  // getChengyuListMethod()
}

function getAllTagsMethod() {
  var param = {
    keyType: 'poetry'
  }
  getAllTags(param).then(async res => {
    if (res.code == 200) {
      // console.log('===========', res.data)
      items.value.allTags = res.data
    }
  }).catch(res => {
    console.log(res)
  })
}

// 点击类型
function clickTag(item, index) {
  items.value.tagTypes.map(item => {
    item.plain = '#392b20'
    return item
  })
  if (item.plain === '#392b20') {
    item.plain = '#f56c6c'
  } else {
    item.plain = '#392b20'
  }
  items.value.tagTypes.splice(index, item)
  searchParam.value.tags = item.name
  getChengyuListMethod()
  // 请求完，先销毁正在播放的音频
  destroyMp3()
}
// 改变分页
function handleCurrentChange() {
  getChengyuListMethod()
  backTop()
  // 请求完，先销毁正在播放的音频
  destroyMp3()

}
// 改变分页
function handleSizeChange() {
  getChengyuListMethod()
  backTop()
  // 请求完，先销毁正在播放的音频
  destroyMp3()
}
function clickDetail(subItem) {
  console.log(subItem.id)
  console.log(subItem.types)
  // encodeURI(subItem.poetryName)
  if (subItem.types == 8) {
    // let path = 'gushiDetail?id=' + subItem.businessId + '&key=' + subItem.businessId
    let route2 = router.resolve({
      path: 'gushiDetail',
      query: {
        id: subItem.businessId,
        key: subItem.businessId
      }
    })
    window.open(route2.href, '_blank')
  }

}

</script>

<template>
  <div>
    <div
      ref="playerRef"
      style="display: none;"
      class="mainPage"
    />
    <div :style="cssData.topCssDataTop">
      <!--000-->
      <!-- <el-row
                :gutter="24"
                style="margin: 10px;"
            >
                <el-col
                    :xs="24"
                    :sm="24"
                    :md="24"
                    :lg="24"
                    :xl="24"
                >
                    <span class="tag-group__title m-1 line-height-2"> &nbsp;&nbsp;</span>
                    <el-input
                        v-model="searchParam.key"
                        size="large"
                        clearable
                        :placeholder="inputSearchTips.searchWord"
                        style="width: 80%;"
                        class="input-with-select"
                        @focus="clickFocus"
                    >
                        <template #append>
                            <el-button
                                color="#95191b"
                                @click="clickSearch()"
                            >
                                <template #icon>
                                    <el-icon>
                                        <svg-icon name="ep:search" />
                                    </el-icon>
                                </template>
                                搜索
                            </el-button>
                        </template>
                    </el-input>
                </el-col>
            </el-row> -->
    </div>
    <!-- <el-divider
            content-position="left"
            style="margin-left: 180px;margin-right: 180px;"
        /> -->
    <div :style="cssData.topCssDataBottom">
      <el-row :gutter="24">
        <el-col
          :xs="24"
          :sm="24"
          :md="18"
          :lg="18"
          :xl="18"
        >
          <el-row
            v-for="subItem in contentList.data"
            :key="subItem.id"
            style="margin-bottom: 10px;"
          >
            <el-col v-if="!contentList.noData">
              <div class="grid-content ep-bg-purple">
                <!-- 古诗 -->
                <el-badge
                  v-if="subItem.types==8"
                  value="古诗"
                  class="item-badge"
                  type="primary"
                >
                  <el-card
                    class="box-card"
                    shadow="always"
                  >
                    <template #header>
                      <div class="card-header">
                        <span>
                          <el-link
                            type="primary"
                            class="card-title"
                            @click="clickDetail(subItem)"
                          >
                            <span
                              style="font-size: 22px;"
                              v-html="subItem.displayName"
                            />
                          </el-link>
                          <el-divider direction="vertical" />
                          <span>{{ subItem.searchData.poetryNamePinyin }} </span>
                        </span>

                        <span>
                          <el-button
                            plain
                            size="small"
                            type="danger"
                            style="margin-right: 15px;"
                            class="mx-2"
                            @click="clickVoice(subItem,'idiom')"
                          >
                            <template #icon>
                              <el-icon>
                                <svg-icon name="ep:video-play" />
                              </el-icon>
                            </template>
                            朗读
                          </el-button>
                          <!-- <el-button
                                                        plain
                                                        size="small"
                                                        color="#95191b"
                                                        class="mx-2"
                                                        @click="printContent()"
                                                    >
                                                        <template #icon>
                                                            <el-icon>
                                                                <svg-icon name="ep:printer" />
                                                            </el-icon>
                                                        </template>
                                                        打印
                                                    </el-button> -->
                          <!-- <el-switch
                                                    v-model="subItem.showHideYi"
                                                    style="margin: 3px;

--el-switch-on-color: #ee5050; --el-switch-off-color: #dcdfe6;"
                                                    inline-prompt
                                                    width="50px"
                                                    active-text="译"
                                                    inactive-text="译"
                                                    @change="changeAll(this)"
                                                />
                                                <el-switch
                                                    v-model="subItem.showHideZhu"
                                                    style="margin: 3px;

--el-switch-on-color: #ee5050; --el-switch-off-color: #dcdfe6;"
                                                    inline-prompt
                                                    width="50px"
                                                    active-text="注释"
                                                    inactive-text="注释"
                                                    @change="changeAll(this)"
                                                />
                                                <el-switch
                                                    v-model="subItem.showHideShang"
                                                    style="margin: 3px;

--el-switch-on-color: #ee5050; --el-switch-off-color: #dcdfe6;"
                                                    width="50px"
                                                    inline-prompt
                                                    active-text="赏析"
                                                    inactive-text="赏析"
                                                    @change="changeAll(this)"
                                                /> -->
                        </span>
                      </div>
                    </template>
                    <div
                      v-for="subData in subItem.searchData.poetryContentList"
                      :key="subData.id"
                      class="text item"
                    >
                      <span
                        style="color: ##2c2c2c;font-size: 18px;line-height: 2.2;"
                        v-html="subData.content"
                      />
                    </div>
                    <div>
                      <el-link disabled>
                        <span style="font-size: 16px;color: #000;">作者:&nbsp;</span>
                      </el-link>
                      <el-link @click="clickSearchFull(subItem.searchData.author)">
                        <span
                          class="card-author"
                          v-html="subItem.searchData.author"
                        />
                      </el-link>

                      <time class="time"> <span>朝代:</span> &nbsp; <span v-html="subItem.searchData.remark" /> </time>
                      <div class="bottom">
                        <!-- <el-button
                                                plain
                                                size="small"
                                                class="mx-2"
                                                color="#EE5050"
                                                @click="playVoice()"
                                            >
                                                <template #icon>
                                                    <el-icon>
                                                        <svg-icon name="ep:video-play" />
                                                    </el-icon>
                                                </template>
                                                朗读
                                            </el-button>
                                            <el-button
                                                plain
                                                size="small"
                                                color="#EE5050"
                                                class="mx-2"
                                                @click="printContent()"
                                            >
                                                <template #icon>
                                                    <el-icon>
                                                        <svg-icon name="ep:printer" />
                                                    </el-icon>
                                                </template>
                                                打印
                                            </el-button> -->
                      </div>
                    </div>
                  </el-card>
                </el-badge>
                <!-- 成语 -->
                <el-badge
                  v-if="subItem.types==7"
                  value="成语"
                  class="item-badge"
                  type="danger"
                >
                  <el-card
                    class="box-card"
                    shadow="always"
                  >
                    <template #header>
                      <div class="card-header">
                        <span>
                          <el-link
                            type="primary"
                            class="card-title"
                          >
                            <span v-html="subItem.displayName" />
                          </el-link>
                          <el-divider direction="vertical" />
                          <span>{{ subItem.searchData.pinyin }} </span>

                          <!-- <span>{{ subItem.searchData.abbreviation }} </span>
                                                    <el-divider
                                                        border-style="dotted"
                                                        style="margin: 4px;"
                                                    /> -->
                        </span>

                        <span>
                          <el-button
                            size="small"
                            type="danger"
                            style="margin-right: 15px;"
                            :plain="subItem.plain||true"
                            class="mx-2"
                            @click="clickVoice(subItem,'chengyu')"
                          >
                            <template #icon>
                              <el-icon>
                                <svg-icon name="ep:bell" />
                              </el-icon>
                            </template>
                            {{ subItem.voiceText||'朗读' }}
                          </el-button>

                        </span>
                      </div>
                    </template>
                    <div class="text item">
                      <el-row
                        :gutter="24"
                        style="width: 100%;padding-bottom: 5px;"
                      >
                        <el-col
                          :xs="24"
                          :sm="24"
                          :md="24"
                          :lg="24"
                          :xl="24"
                        >
                          <div class="text item">
                            <span style="color: #2c2c2c;font-size: 18px;line-height: 2.2;">解释： <span v-html="subItem.searchData.explanation" /> </span>
                            <el-divider
                              border-style="dotted"
                              style="margin: 4px;"
                            />
                            <span style="color: #2c2c2c;font-size: 18px;line-height: 2.2;">例子： <span v-html="subItem.searchData.example" /></span>
                            <el-divider
                              border-style="dotted"
                              style="margin: 4px;"
                            />
                            <span style="color: #999;font-size: 12px;line-height: 2.2;">出自：<span v-html="subItem.searchData.derivation" /></span>
                          </div>
                        </el-col>
                      </el-row>
                    </div>

                    <!-- <div style="margin-top: 8px;">
                                            <div class="bottom" />
                                        </div> -->
                  </el-card>
                </el-badge>
              </div>
            </el-col>
          </el-row>
          <el-row v-if="contentList.noData">
            <el-col>
              <el-empty description="暂无数据" />
            </el-col>
          </el-row>
          <br>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="6"
          :lg="6"
          :xl="6"
        >
          <div class="grid-content ep-bg-purple">
            <el-card
              :body-style="{ padding: '2px' }"
              class="box-card"
              style="height: auto;margin-top: 10px;"
            >
              <div style="padding: 14px;">
                <el-row>
                  <el-col
                    :xs="24"
                    :sm="24"
                    :md="24"
                    :lg="12"
                    :xl="12"
                  >
                    <el-image
                      style="width: 100px; height: 100px;"
                      class="image"
                      src="https://static.lehaoyuan.com/logo/lehaoyuan.jpg"
                      fit="fill"
                    />
                  </el-col>
                  <el-col
                    :xs="24"
                    :sm="24"
                    :md="24"
                    :lg="12"
                    :xl="12"
                  >
                    <p class="qrcode">关注公众号</p>
                    <p class="qrcode-text">每天了解一点</p>
                  </el-col>
                </el-row>
                <!-- <div class="bottom">
                                    <time class="time">推送最新</time>
                                    <el-button text class="button">Operating</el-button>
                                </div> -->
              </div>
            </el-card>
            <br>
            <el-card
              class="box-card"
              style="height: auto;"
            >
              <template #header>
                <div class="card-header">
                  <span>标签</span>
                  <span>
                    <!-- <el-button
                                            size="small"
                                            color="#EE5050"
                                            plain
                                            class="mx-2"
                                            @click="printContent()"
                                        >
                                            打印预览
                                        </el-button> -->
                  </span>
                </div>
              </template>
              <div class="flex  mb-3 flex-wrap gap-3">
                <div class="text">
                  <el-row
                    :gutter="24"
                    style="width: 100%;"
                  >
                    <el-col
                      :xs="24"
                      :sm="24"
                      :md="24"
                      :lg="24"
                      :xl="24"
                    >
                      <div style="font-size: 16px;color: #303133;font-weight: bold;">
                        <!--
                                                    v-for="item in items.allTags"
                                                    :key="item"
                                                -->
                        <el-row>
                          <el-col
                            v-for="subItem in items.allTags"
                            :key="subItem"
                            :span="12"
                          >
                            <el-button
                              round
                              :auto-insert-space="true"
                              style="width: 90px;border-color: #dcdfe6;"
                              plain
                            >
                              <el-link @click="clickSearchFull(subItem.originStr,'tag')">
                                <div v-html="subItem.originStr " />
                              </el-link>
                            </el-button>
                          </el-col>

                          <!-- <el-button round :auto-insert-space="true" style="width: 90px;border-color: #dcdfe6;" plain>
                                                        <el-link :auto-insert-space="true" @click="clickSearchFull(subItem.author)">
                                                            小&nbsp;&nbsp;学&nbsp;&nbsp;生
                                                        </el-link>
                                                    </el-button>
                                                    <el-button round :auto-insert-space="true" style="width: 90px;border-color: #dcdfe6;" plain>
                                                        <el-link @click="clickSearchFull(subItem.author)">
                                                            初中文言文
                                                        </el-link>
                                                    </el-button> -->
                        </el-row>
                      </div>
                      <br>

                      <!-- <el-divider
                                                border-style="dotted"
                                                style="margin: 3px;"
                                            /> -->
                      <el-link @click="clickSearchFull(subItem.author)">
                        <span
                          style="color: #2c2c2c;"
                          class="link-text card-author"
                        >&nbsp;</span>
                      </el-link>
                    </el-col>
                    <el-divider
                      border-style="double"
                      style="margin: 14px;"
                    />
                  </el-row>
                </div>
              </div>
            </el-card>
            <br>
            <el-card
              class="box-card"
              style="height: auto;"
            >
              <template #header>
                <div class="card-header">
                  <span>今日一读</span>
                  <span>
                    <!-- <el-button
                                            size="small"
                                            color="#EE5050"
                                            plain
                                            class="mx-2"
                                            @click="printContent()"
                                        >
                                            打印预览
                                        </el-button> -->
                  </span>
                </div>
              </template>
              <div class="flex  mb-3 flex-wrap gap-3">
                <div
                  v-for="subItem in todayData.data"
                  :key="subItem.id"
                  class="text"
                >
                  <el-row
                    :gutter="24"
                    style="width: 100%;"
                  >
                    <el-col
                      :xs="24"
                      :sm="24"
                      :md="24"
                      :lg="24"
                      :xl="24"
                    >
                      <div style="font-size: 16px;color: #303133;font-weight: bold;">
                        <el-link
                          class="card-title-right"
                          :underline="true"
                          @click="clickSearchFull(subItem.poetryName,'content')"
                        >
                          {{ subItem.poetryName }}
                        </el-link>
                      </div>
                      <el-divider
                        border-style="dotted"
                        style="margin: 3px;"
                      />
                      <el-link @click="clickSearchFull(subItem.author,'content')">
                        <span
                          style="color: #000;"
                          class="link-text card-author"
                          v-html="subItem.author"
                        />&nbsp;
                      </el-link>
                      <el-link disabled>
                        <span
                          style="color: #000;font-size: 16px;"
                          v-html="subItem.remark"
                        />
                      </el-link>
                    </el-col>
                    <el-divider
                      border-style="double"
                      style="margin: 14px;"
                    />
                  </el-row>
                </div>
              </div>
            </el-card>
            <br>
            <el-card
              class="box-card"
              style="height: auto;"
            >
              <template #header>
                <div class="card-header">
                  <span>浏览排行</span>
                </div>
              </template>
              <div class="flex  mb-3 flex-wrap gap-3">
                <div
                  v-for="(subItem,index) in topsData.data"
                  :key="subItem.id"
                  class="text"
                >
                  <el-row
                    :gutter="24"
                    style="width: 100%;"
                  >
                    <el-col
                      :xs="24"
                      :sm="24"
                      :md="24"
                      :lg="24"
                      :xl="24"
                    >
                      <div style="font-size: 15px;">
                        <span
                          v-if="index==0"
                          class="spot"
                          style="background: #f94e4c;"
                        >{{ index+1 }}</span>
                        <span
                          v-if="index==1"
                          class="spot"
                          style="background: #ff980d;"
                        >{{ index+1 }}</span>
                        <span
                          v-if="index==2"
                          class="spot"
                          style="background: #d09500;"
                        >{{ index+1 }}</span>
                        <span
                          v-if="index!=0&&index!=1&&index!=2"
                          class="spot"
                        >{{ index+1 }}</span>
                        <span>
                          <el-link
                            class="card-title-right"
                            :underline="true"
                            @click="clickSearchFull(subItem.poetryName,'content')"
                          >
                            {{ subItem.poetryName }}
                          </el-link>
                        </span>
                      </div>
                      <el-divider
                        border-style="dotted"
                        style="margin: 3px;"
                      />
                      <el-link @click="clickSearchFull(subItem.author,'content')">
                        <span
                          style="color: #000;"
                          class="link-text card-author"
                          v-html="subItem.author"
                        />&nbsp;
                      </el-link>

                      <el-link disabled>
                        <span
                          style="color: #999;font-size: 16px;"
                          v-html="subItem.remark"
                        />
                      </el-link>
                    </el-col>
                    <el-divider
                      border-style="double"
                      style="margin: 14px;"
                    />
                  </el-row>
                </div>
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
      <el-row style="margin-left: 180px;margin-right: 180px;">
        <el-pagination
          v-model:currentPage="contentList.pageNo"
          :page-size="contentList.pageSize"
          prev-text="上一页"
          next-text="下一页"
          style="color: #000;"
          background
          layout="total, prev, pager, next"
          :total="contentList.totalRecord"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-row>
    </div>
    <el-divider
      content-position="left"
      :style="cssData.topCssDataBottom"
    />

    <page-main
      title="经典歇后语"
      :style="cssData.topCssDataBottom"
    >
      <div class="question">
        <ol class="answer">
          <li
            v-for="boottomItem in botomData.data"
            :key="boottomItem.id"
          >
            <span>{{ boottomItem.riddle }}</span>
            {{ boottomItem.answer }}
          </li>
        </ol>
      </div>
    </page-main>
  </div>
</template>
<style>
.el-pagination.is-background .el-pager li:not(.is-disabled).is-active {
	background-color: #f56c6c;
	color: #fff;
	font-weight: bold;
}
.el-pagination.is-background .el-pager li:not(.is-disabled):hover {
	color: #fff;
	background-color: #f56c6c;
}
.el-pagination.is-background .el-pager li:not(.is-disabled):hover .el-pagination.is-background .btn-prev:hover:not([disabled]) {
	color: #f56c6c;
}
.el-pagination.is-background .btn-next:hover:not([disabled]) {
	color: #f56c6c;
}
</style>
<style lang="scss" scoped>
.spot {
	float: left;
	width: 20px;
	height: 19px;
	background: #382b1f;
	color: #f7f5ef;
	font-size: 12px;
	text-align: center;
	line-height: 19px;
	border-radius: 50%;
	margin-right: 10px;
	margin-top: 3px;
}
.link-text {
	color: #000;
	font-size: 16px;
}
.mx-1 {
	margin: 0.35rem;
	font-size: 16px;
}
.mx-2 {
	margin: 0.15rem;
	font-size: 12px;
}
.radius {
	height: 40px;
	width: 70%;
	border: 1px solid var(--el-border-color);
	border-radius: 0;
	margin-top: 20px;
}
.card-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.card-title {
	color: #333;
	font-size: 22px;
	font-weight: bold;
}
.qrcode {
	color: #333;
	font-size: 18px;
	font-weight: bold;
}
.qrcode-text {
	color: #333;
	font-size: 16px;
}
.card-title-right {
	color: #303133;
	font-size: 16px;
	font-weight: bold;
}
.card-author {
	font-weight: bold;
	font-size: 16px;
}
.text {
	font-size: 16px;
}
.item {
	margin-bottom: 0;
}
.box-card {
	border-radius: 16px;
	width: auto;
	height: auto;
}
.time {
	font-size: 12px;
	color: #999;
	margin-top: 13px;
	line-height: 12px;
	display: flow-root;
	justify-content: space-between;
}
.bottom {
	margin-top: 13px;
	line-height: 12px;
	display: flex;
	justify-content: end;
	align-items: center;
}
.time2 {
	font-size: 12px;
	color: #999;
}
.bottom2 {
	margin-top: 13px;
	line-height: 12px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.button {
	padding: 0;
	min-height: auto;
}
.image {
	width: 100%;
	display: block;
}
.el-row {
	margin-bottom: 5px;
}
.el-row:last-child {
	margin-bottom: 0;
}
.el-col {
	border-radius: 4px;
}
.grid-content {
	border-radius: 4px;
	min-height: 36px;
}
.text-emphasis {
	text-emphasis-style: '❤';
}
.ecology {
	padding: 10px 0 0;
	&.vue {
		h1 {
			color: #41b883;
		}
	}
	&.fa {
		h1 {
			color: #e60000;
		}
	}
	&.osa {
		h1 {
			color: #67c23a;
		}
	}
	.main {
		text-align: center;
		img {
			display: block;
			margin: 0 auto;
		}
		h1 {
			margin: 10px auto 0;
			text-align: center;
		}
		h2 {
			font-size: 16px;
			font-weight: normal;
			color: var(--el-text-color-secondary);
			text-align: center;
		}
	}
	.el-carousel {
		box-shadow: var(--el-box-shadow-light);
		transition: var(--el-transition-box-shadow);
	}
	ul li {
		line-height: 28px;
	}
}
.item-badge {
	margin-top: 10px;
	width: 98%;
}
.question {
	.answer {
		margin: 20px 0 0;
		padding-left: 20px;
		font-size: 16px;
		color: var(--el-text-color-secondary);
		li {
			margin-bottom: 10px;
			line-height: 1.5;
			&:last-child {
				margin-bottom: 0;
			}
		}
		span {
			color: var(--el-text-color-primary);
			font-weight: bold;
		}
	}
}
.input-with-select .el-input-group__prepend {
	background-color: var(--el-fill-color-blank);
}
</style>
